// Elimina el atributo zone de los parcelShops en localStorage q han sido eliminados con una zona borrada
export function localStorageParcelShopsFromDeletedZone(parcelShopIds) {
  if (!parcelShopIds || parcelShopIds.length === 0) return;

  const parcelShopsData = JSON.parse(
    localStorage.getItem('parcelShopsData') || '{"shops":[]}'
  );

  if (
    parcelShopsData &&
    parcelShopsData.shops &&
    parcelShopsData.shops.length > 0
  ) {
    const updatedShops = parcelShopsData.shops.map(shop => {
      if (parcelShopIds.includes(shop.id) && shop.zone) {
        const { zone: _, ...shopWithoutZone } = shop;
        return shopWithoutZone;
      }
      return shop;
    });

    parcelShopsData.shops = updatedShops;
    localStorage.setItem('parcelShopsData', JSON.stringify(parcelShopsData));
  }
}

// Añade el atributo zone a los parcelShops en localStorage q han sido incluidos en una zona nueva
export function localStorageParcelShopsFromCreatedZone(
  parcelShopIds,
  zoneName
) {
  if (!parcelShopIds || parcelShopIds.length === 0) return;

  const parcelShopsData = JSON.parse(
    localStorage.getItem('parcelShopsData') || '{"shops":[]}'
  );

  if (
    parcelShopsData &&
    parcelShopsData.shops &&
    parcelShopsData.shops.length > 0
  ) {
    const updatedShops = parcelShopsData.shops.map(shop => {
      if (parcelShopIds.includes(shop.id) && !shop.zone) {
        return { ...shop, zone: { name: zoneName } };
      }
      return shop;
    });

    parcelShopsData.shops = updatedShops;
    localStorage.setItem('parcelShopsData', JSON.stringify(parcelShopsData));
  }
}

// Actualiza el atributo zone de los parcelShops en localStorage q han sido editados en una zona
export function localStorageParcelShopsFromUpdatedZone(
  parcelShopIds,
  zoneCode
) {
  if (!parcelShopIds || parcelShopIds.length === 0) return;

  const parcelShopsData = JSON.parse(
    localStorage.getItem('parcelShopsData') || '{"shops":[]}'
  );

  if (
    parcelShopsData &&
    parcelShopsData.shops &&
    parcelShopsData.shops.length > 0
  ) {
    const updatedShops = parcelShopsData.shops.map(shop => {
      // Primero eliminamos la zona de todas las tiendas que coincidan con el zoneCode
      if (shop.zone && shop.zone.code === zoneCode) {
        const { zone: _, ...shopWithoutZone } = shop;
        return shopWithoutZone;
      }

      // Luego agregamos la zona solo a las tiendas que coincidan con los IDs proporcionados
      if (parcelShopIds.includes(shop.id)) {
        return { ...shop, zone: { code: zoneCode } };
      }

      return shop;
    });

    parcelShopsData.shops = updatedShops;
    localStorage.setItem('parcelShopsData', JSON.stringify(parcelShopsData));
  }
}

// Recorre el localStorage para encontrar las coordenadas de los IDs de estos parcel shops.
// Devuelve un array de coordenadas
export function localStorageGetParcelShopsCoordinates(parcelShopIds) {
  if (!parcelShopIds || parcelShopIds.length === 0) return [];

  const parcelShopsData = JSON.parse(
    localStorage.getItem('parcelShopsData') || '{"shops":[]}'
  );

  const parcelShops = parcelShopsData.shops.filter(shop =>
    parcelShopIds.includes(shop.id)
  );

  return parcelShops.map(shop => shop.address.location.geometry.coordinates);
}
