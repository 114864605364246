import { useMap } from '@vis.gl/react-google-maps';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  memo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import StopMarker from './StopMarker';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { ClusterRenderer } from './ClusterRenderer';

// Estilos para el pop-up - compartidos entre todos los marcadores
export const infoWindowStyles = {
  container: {
    padding: '8px 12px',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    minWidth: '150px',
    cursor: 'pointer'
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '5px',
    borderBottom: '1px solid #eee',
    paddingBottom: '5px'
  },
  row: {
    margin: '5px 0',
    display: 'flex'
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
    minWidth: '80px'
  },
  value: {
    fontWeight: 'normal'
  }
};

// Info al hacer clñick en un stopmarker
const InfoContent = memo(({ t, stop, onClose }) => (
  <div style={infoWindowStyles.container} onClick={onClose}>
    <div style={infoWindowStyles.title}>{t('Detalle de punto')}</div>
    <div style={infoWindowStyles.row}>
      <span style={infoWindowStyles.label}>{t('Fecha')}:</span>
      <span style={infoWindowStyles.value}>{stop.date || '-'}</span>
    </div>
    <div style={infoWindowStyles.row}>
      <span style={infoWindowStyles.label}>{t('Servicio')}:</span>
      <span style={infoWindowStyles.value}>{stop.service || '-'}</span>
    </div>
    <div style={infoWindowStyles.row}>
      <span style={infoWindowStyles.label}>{t('Código Postal')}:</span>
      <span style={infoWindowStyles.value}>
        {stop.address?.postalCode || '-'}
      </span>
    </div>
  </div>
));

InfoContent.propTypes = {
  t: PropTypes.func.isRequired,
  stop: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

// Estilos simples para cada punto de stopmarker
const LightPin = memo(() => (
  <div
    style={{
      width: '11px',
      height: '11px',
      backgroundColor: '#051ab1',
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer'
    }}
  />
));

const StopsMarkers = memo(props => {
  const { stops } = props;
  const [markers, setMarkers] = useState({});
  const map = useMap();
  const clustererRef = useRef(null);
  const prevStopsRef = useRef([]);

  useEffect(() => {
    if (!map) return;
    if (!stops) return;

    if (!clustererRef.current) {
      clustererRef.current = new MarkerClusterer({
        map,
        renderer: new ClusterRenderer(),
        // Optimizar para volúmenes grandes incrementando maxZoom y gridSize
        maxZoom: 18,
        gridSize: 40,
        minimumClusterSize: 6,
        // Usar algoritmo estándar sin personalización
        algorithm: MarkerClusterer.DEFAULT_ALGORITHM
      });
    }

    return () => {
      if (clustererRef.current) {
        clustererRef.current.clearMarkers();
        clustererRef.current = null;
      }
    };
  }, [map]);

  // Función optimizada para gestionar marcadores
  const setMarkerRef = useCallback((marker, key) => {
    if (!marker) {
      setMarkers(prevMarkers => {
        if (!prevMarkers[key]) return prevMarkers;

        const { [key]: _, ...newMarkers } = prevMarkers;
        return newMarkers;
      });
      return;
    }

    setMarkers(prevMarkers => {
      if (prevMarkers[key]) return prevMarkers;
      return { ...prevMarkers, [key]: marker };
    });
  }, []);

  useEffect(() => {
    if (!clustererRef.current) return;

    // Evitar trabajo innecesario si no hay marcadores
    const markerArray = Object.values(markers);
    if (markerArray.length === 0) {
      clustererRef.current.clearMarkers();
      return;
    }

    // Forzar actualización siempre para asegurar que se reflejen los filtros inmediatamente
    clustererRef.current.clearMarkers();
    clustererRef.current.addMarkers(markerArray);
    prevStopsRef.current = [...stops];
  }, [markers, stops]);

  return (
    <>
      {stops.map(stop => (
        <StopMarker
          key={stop.id}
          stop={stop}
          setMarkerRef={setMarkerRef}
          totalMarkers={stops.length}
        />
      ))}
    </>
  );
});

StopsMarkers.propTypes = {
  stops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired
};

// Exportamos tanto los componentes secundarios como el principal
export { InfoContent, LightPin };
export default StopsMarkers;
