import { MarkerUtils } from '@googlemaps/markerclusterer';

export class ClusterRenderer {
  constructor() {
    if (!ClusterRenderer.svgCache) {
      ClusterRenderer.svgCache = new Map();
    }
  }

  render({ count, position }, stats, map) {
    const google = window.google;

    // Usar SVG cacheado si existe
    const cacheKey = `${count}-${position.lat}`;
    let svg = ClusterRenderer.svgCache.get(cacheKey);

    if (!svg) {
      svg = `<svg fill="#061AB1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
        <circle cx="120" cy="120" opacity=".6" r="70" />
        <circle cx="120" cy="120" opacity=".3" r="90" />
        <circle cx="120" cy="120" opacity=".2" r="110" />
        <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
      </svg>`;

      ClusterRenderer.svgCache.set(cacheKey, svg);
    }

    const title = `Cluster of ${count} markers`,
      // adjust zIndex to be above other markers
      zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;

    if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
      // create cluster SVG element
      const parser = new DOMParser();
      const svgEl = parser.parseFromString(
        svg,
        'image/svg+xml'
      ).documentElement;
      svgEl.setAttribute('transform', 'translate(0 25)');

      const clusterOptions = {
        map,
        position,
        zIndex,
        title,
        content: svgEl
      };
      return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
    }

    const clusterOptions = {
      position,
      zIndex,
      title,
      icon: {
        url: `data:image/svg+xml;base64,${btoa(svg)}`,
        anchor: new google.maps.Point(25, 25)
      }
    };
    return new google.maps.Marker(clusterOptions);
  }
}
