import React, { useEffect, useState, useContext } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';
import { parcelShopsFindAll } from '../helpers/API/closerAPI';
import { ReactComponent as ParcelShopIcon } from '../assets/img/icons/ps-locker.svg';
import { ReactComponent as ParcelShopShopIcon } from '../assets/img/icons/ps-shop.svg';
import { MapContext } from '../providers/mapProvider';

const ParcelShopMarker = ({ parcelShop, isSelected, isHidden }) => {
  const { createZoneMode } = useContext(MapContext);
  if (!parcelShop?.address?.location?.geometry?.coordinates) {
    console.warn('Marker de un Parcelshop sin geometría válida:', parcelShop);
    return null;
  }

  const position = {
    lat: parcelShop.address.location.geometry.coordinates[1],
    lng: parcelShop.address.location.geometry.coordinates[0]
  };

  return (
    <AdvancedMarker
      position={position}
      title={
        parcelShop.name +
        ' ' +
        (parcelShop.zone?.name
          ? '- asignado a zona: ' + parcelShop.zone?.name
          : '') +
        (parcelShop.state?.code === 'disabled' ? ' - Inactivo' : '')
      }
    >
      <div
        style={{
          fontSize: '28px',
          transform: isSelected ? 'scale(1.5)' : 'scale(1)',
          transition: 'all 0.3s ease',
          display: isHidden ? 'none' : 'block',
          opacity:
            createZoneMode === 'DATA' && isSelected
              ? 1
              : createZoneMode === 'DATA' && !isSelected
                ? 0.5
                : parcelShop.state?.code === 'disabled' || parcelShop.zone?.name
                  ? 0.5
                  : 1
        }}
      >
        {parcelShop.type === 'shop' && <ParcelShopShopIcon />}
        {parcelShop.type === 'locker' && <ParcelShopIcon />}
      </div>
    </AdvancedMarker>
  );
};

const ParcelShopMarkers = () => {
  const [parcelShops, setParcelShops] = useState(() => {
    const stored = JSON.parse(
      localStorage.getItem('parcelShopsData') || '{"shops":[],"token":""}'
    );
    return stored.shops;
  });
  const [selectedShopIds, setSelectedShopIds] = useState([]);
  const [hiddenShopsIds, setHiddenShopsIds] = useState([]);

  useEffect(() => {
    const handleParcelShopSelected = event => {
      setSelectedShopIds(event.detail);
    };

    const handleCancelEdit = () => {
      setSelectedShopIds([]);
    };

    const handleUpdateParcelShopSelection = event => {
      setSelectedShopIds(event.detail);
    };

    const handleHideParcelShops = event => {
      if (event.detail.includes('all')) {
        // Si se recibe 'all', ocultar todos los parcelshops
        const allIds = parcelShops.map(shop => shop.id);
        setHiddenShopsIds(allIds);
      } else {
        setHiddenShopsIds(hiddenShopsIds => [
          ...hiddenShopsIds,
          ...event.detail
        ]);
      }
    };

    const handleUnHideParcelShops = event => {
      if (event.detail.includes('all')) {
        // Si se recibe 'all', mostrar todos los parcelshops
        setHiddenShopsIds([]);
      } else {
        setHiddenShopsIds(hiddenShopsIds =>
          hiddenShopsIds.filter(id => !event.detail.includes(id))
        );
      }
    };

    window.addEventListener('parcelShopSelected', handleParcelShopSelected);
    window.addEventListener('cancelEditZone', handleCancelEdit);
    window.addEventListener('hideParcelShops', handleHideParcelShops);
    window.addEventListener('unHideParcelShops', handleUnHideParcelShops);
    window.addEventListener(
      'updateParcelShopSelection',
      handleUpdateParcelShopSelection
    );

    return () => {
      window.removeEventListener(
        'parcelShopSelected',
        handleParcelShopSelected
      );
      window.removeEventListener('cancelEditZone', handleCancelEdit);
      window.removeEventListener('hideParcelShops', handleHideParcelShops);
      window.removeEventListener('unHideParcelShops', handleUnHideParcelShops);
      window.removeEventListener(
        'updateParcelShopSelection',
        handleUpdateParcelShopSelection
      );
    };
  }, [parcelShops]);

  useEffect(() => {
    const fetchParcelShops = async () => {
      try {
        const currentToken = new URLSearchParams(window.location.search).get(
          'token'
        );

        const newParcelShops = (await parcelShopsFindAll()) || [];
        setParcelShops(newParcelShops);

        localStorage.setItem(
          'parcelShopsData',
          JSON.stringify({
            shops: newParcelShops,
            token: currentToken
          })
        );
      } catch (error) {
        console.info('Error al obtener puntos de entrega:', error);
      }
    };

    fetchParcelShops();
  }, []);

  return (
    <>
      {parcelShops
        .filter(parcelShop => parcelShop.type !== 'agency')
        .map(parcelShop => (
          <ParcelShopMarker
            key={parcelShop.id || parcelShop.name}
            parcelShop={parcelShop}
            isSelected={selectedShopIds.includes(parcelShop.id)}
            isHidden={hiddenShopsIds.includes(parcelShop.id)}
          />
        ))}
    </>
  );
};

ParcelShopMarker.propTypes = {
  parcelShop: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    address: PropTypes.shape({
      country: PropTypes.string,
      postalCode: PropTypes.string,
      location: PropTypes.shape({
        geometry: PropTypes.shape({
          type: PropTypes.string.isRequired,
          coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
        }).isRequired,
        precision: PropTypes.string
      }).isRequired,
      locality: PropTypes.string,
      street: PropTypes.string
    }).isRequired
  }).isRequired
};

export default ParcelShopMarkers;
