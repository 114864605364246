import { APIClient, setAuthorization } from './apiHelper';
import { hideStops, setItemToStore } from '../utils';
import moment from 'moment';
import { GeometryTypes } from '../../dataGLS/polygon';
import {
  coordinatesZoneArrayToObject,
  coordinatesZoneObjectToArray
} from '../zoneCalc';
import { coordinatesArrayToObject } from '../polygonUtils';
import { zonesSort } from '../../dataGLS/zoneData';

const apiClient = new APIClient();

const formatDriverStructure = driver => {
  if (!driver) return null;

  if (driver.code && driver.agency?.code) {
    return {
      code: driver.code,
      agency: {
        code: driver.agency.code
      }
    };
  }

  return null;
};

export const login = async token => {
  const response = await apiClient.post('/user/login/jwt', {
    login: {
      token
    }
  });
  const user = response.data.user;
  //setItemToStore('user', JSON.stringify(user));
  const accesstoken = response.headers['x-access-token'];
  setItemToStore('token', accesstoken);
  setAuthorization(accesstoken);
  return user;
};

export const zonesFindAll = async () => {
  const response = await apiClient.post('/user/zones/find', {
    find: {
      query: 'all'
    }
  });
  if (!response.data) return response;
  const zones = response.data.found;
  return zones
    .map(zone => {
      return coordinatesZoneArrayToObject(zone);
    })
    .sort(zonesSort);
};

export const postalCodesFinsAll = async () => {
  const response = await apiClient.post('/user/postalCodes/find', {
    find: {
      query: 'all'
    }
  });
  if (!response.data) return response;
  const postalCodes = response.data.found;
  return postalCodes.map(postalCode => {
    const coordinates = coordinatesArrayToObject(postalCode, 'polygon');
    return {
      ...postalCode,
      centroid: {
        ...postalCode.centroid,
        coordinates: {
          lat: postalCode.centroid.coordinates[1],
          lng: postalCode.centroid.coordinates[0]
        }
      },
      polygon: {
        ...postalCode.polygon,
        coordinates,
        type: GeometryTypes.MultiPolygon
      }
    };
  });
};

export const stopsFindAll = async date => {
  //To prevent accidental downloading if hideStops is activated
  if (hideStops) return [];
  const response = await apiClient.post('/user/stops/load', {
    load: {
      //date
      date: moment(date).format('YYYY-MM-DD')
    }
  });
  if (!response.data) return response;
  const stops = response.data.results;
  return stops.map((stop, index) => {
    return {
      ...stop,
      id: `stop-${stop.date}-${index}`,
      address: {
        ...stop.address,
        location: {
          ...stop.address.location,
          coordinates: {
            lat: stop.address.location.coordinates[1],
            lng: stop.address.location.coordinates[0]
          }
        }
      }
    };
  });
};

export const getStopsServices = async () => {
  const response = await apiClient.post('/user/stops/getServices', {
    get: {}
  });
  if (!response.data) return response;
  return response.data.results;
};

export const getStopsPostalCodes = async () => {
  const response = await apiClient.post('/user/stops/getPostalCodes', {
    get: {}
  });
  if (!response.data) return response;
  return response.data.results;
};
export const getStopsDateRanges = async () => {
  const response = await apiClient.post('/user/stops/getDateRanges', {
    get: {}
  });
  if (!response.data) return response;
  return response.data.results;
};

export const zonesCreate = async data => {
  let updateFields = [
    'info',
    'name',
    'type',
    'status',
    'driver',
    'temporary',
    'services',
    'postalCodes',
    'priority',
    'actions',
    'geometry',
    'dateRange',
    'zoneType',
    'parcelShops'
  ];
  if (!hideStops) {
    updateFields = [...updateFields, 'thresholds'];
  }
  const create = {};
  updateFields.forEach(field => {
    if (field === 'geometry' && data[field]) {
      create.geometry = coordinatesZoneObjectToArray(
        structuredClone(data)
      ).geometry;
    } else if (field === 'driver' && data[field]) {
      create.driver = formatDriverStructure(data[field]);
    } else if (data[field]) {
      create[field] = data[field];
    }
  });
  const response = await apiClient.post('/user/zones/create', {
    create
  });
  if (!response.data) return response;
  if (!response.data.created) return response.data;
  return coordinatesZoneArrayToObject(response.data.created);
};

const createUpdateZoneRequestObject = (
  id,
  data,
  updateFields = [],
  removeFields = []
) => {
  if (hideStops) {
    updateFields = updateFields.filter(field => field !== 'thresholds');
  }
  const set = {};
  const unset = {};

  updateFields.forEach(field => {
    if (field === 'geometry' && data[field]) {
      set.geometry = coordinatesZoneObjectToArray(
        structuredClone(data)
      ).geometry;
    } else if (field === 'driver') {
      const formattedDriver = formatDriverStructure(data[field]);
      if (formattedDriver) {
        set.driver = formattedDriver;
      }
    } else if (data[field]) {
      set[field] = data[field];
    }
  });

  removeFields.forEach(field => {
    if (!data[field]) {
      unset[field] = true;
    }
  });

  if (updateFields.includes('driver') && !set.driver) {
    unset.driver = true;
  }

  return {
    find: {
      query: 'id',
      id
    },
    set,
    unset
  };
};

export const zonesUpdate = async (
  id,
  data,
  updateFields = [
    'info',
    'name',
    'type',
    'thresholds',
    'status',
    'driver',
    'temporary',
    'services',
    'postalCodes',
    'priority',
    'actions',
    'geometry',
    'dateRange',
    'zoneType',
    'parcelShops'
  ],
  removeFields = ['dateRange']
) => {
  const updateData = createUpdateZoneRequestObject(
    id,
    data,
    updateFields,
    removeFields
  );
  const response = await apiClient.post('/user/zones/update', updateData);
  if (!response.data) return response;
  return response.data;
};

export const zonesUpdateMany = async (
  zones,
  updateFields = [
    'info',
    'name',
    'type',
    'thresholds',
    'status',
    'driver',
    'temporary',
    'services',
    'postalCodes',
    'priority',
    'actions',
    'geometry',
    'dateRange',
    'zoneType',
    'parcelShops'
  ],
  removeFields = ['dateRange']
) => {
  if (hideStops) {
    updateFields = updateFields.filter(field => field !== 'thresholds');
  }
  const requestData = [];
  console.log('zonesUpdateMany', zonesUpdateMany);
  zones.forEach(zone => {
    const data = createUpdateZoneRequestObject(
      zone.id,
      zone,
      updateFields,
      removeFields
    );
    requestData.push(data);
  });

  const response = await apiClient.post('/user/zones/updateMany', requestData);
  if (!response.data) return response;
  return response.data;
};
export const zonesUpdateStatus = async (id, status) => {
  const update = {
    status: {
      code: status,
      at: moment().format('YYYY-MM-DDTHH:mm:ssZ')
    }
  };

  const response = await apiClient.post('/user/zones/update', {
    find: {
      query: 'id',
      id
    },
    set: update
  });
  if (!response.data) return response;
  return response.data;
};

export const zonesDelete = async code => {
  const data = {
    delete: {
      query: 'code',
      code
    }
  };
  const response = await apiClient.post('/user/zones/delete', data);
  if (!response.data) return response;
  return response.data;
};

export const driversFindAll = async () => {
  const response = await apiClient.post('/user/drivers/find', {
    find: {
      query: 'all'
    }
  });
  if (!response.data) return response;
  return response.data.found;
};

export const agenciesFindCurrent = async () => {
  const response = await apiClient.post('/user/agencies/find', {
    find: {}
  });
  if (!response.data) return response;
  return response.data.found;
};

export const parcelShopsFindAll = async () => {
  const response = await apiClient.post('/user/parcelShops/find', {
    find: {
      query: 'all',
      includeDisabled: 15
    }
  });
  if (!response.data) return response;
  return response.data.found;
};
