import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from 'react';
import { withTranslation } from 'react-i18next';
import {
  ControlPosition,
  Map,
  MapControl,
  useMap
} from '@vis.gl/react-google-maps';
import { useDrawingManager } from './useDrawingManager';
import { useDrawingManagerEvents } from './drawingManagerListeners';
import { MapContext } from '../providers/mapProvider';
import StopsMarkers from './StopsMarkers';
import AgencyMarkers from './AgencyMarkers';
import ParcelShopMarkers from './ParcelShopMarkers';
import { CreateZoneModes } from '../dataGLS/CloserModes';
import MapToolbar from './MapToolbar';

const CloserMap = () => {
  const map = useMap();
  const managerListener = useRef(null);
  const mapProvider = useContext(MapContext);
  const {
    createZoneMode,
    selectedZone,
    centerArea,
    stopsFiltered,
    mapBounds,
    mapState,
    setMapState,
    showStops,
    maxVisiblePoints
  } = mapProvider;

  const defaultCenter = {
    lat: 39.4398223,
    lng: -0.4720902
  };

  const [center, setCenter] = useState(defaultCenter);
  const [visibleStops, setVisibleStops] = useState([]);

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    disableDefaultUI: true,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false,
    renderingType: 'VECTOR',
    tilt: 0
  };

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  const drawingManagerRef = useRef();
  drawingManagerRef.current = useDrawingManager();
  const selectedPolygonRef = useRef(null);

  managerListener.current = useDrawingManagerEvents(
    drawingManagerRef.current,
    mapProvider,
    selectedPolygonRef
  );

  // Filtrar stops visibles por área y límite
  useEffect(() => {
    if (!map || !showStops) {
      setVisibleStops([]);
      return;
    }

    // Función para filtrar stops visibles en el viewport actual
    const filterVisibleStops = () => {
      if (!stopsFiltered?.length) {
        setVisibleStops([]);
        return;
      }

      const bounds = map.getBounds();
      if (!bounds) {
        // Si no hay bounds, mostrar un número limitado
        setVisibleStops(stopsFiltered.slice(0, maxVisiblePoints));
        return;
      }

      // Filtrar puntos dentro del área visible y aumentar el límite
      const filtered = stopsFiltered
        .filter(stop => {
          const { lat, lng } = stop.address.location.coordinates;
          return bounds.contains({ lat, lng });
        })
        .slice(0, maxVisiblePoints);

      setVisibleStops(filtered);
    };

    // Filtrar inicialmente
    filterVisibleStops();

    // Agregar listener para actualizar cuando cambia el viewport
    const listener = map.addListener('idle', filterVisibleStops);

    // Limpiar listener al desmontar
    return () => {
      if (window && window.google) {
        window.google.maps.event.removeListener(listener);
      }
    };
  }, [map, stopsFiltered, showStops, maxVisiblePoints]);

  useEffect(() => {
    if (!mapBounds || !map) return;
    map.fitBounds(mapBounds);
  }, [mapBounds, map]);

  useEffect(() => {
    if (!createZoneMode || !drawingManagerRef.current) return;
    if (managerListener.current) {
      managerListener.current.deselectAllPolygons();
    }
  }, [createZoneMode]);

  useEffect(() => {
    if (!centerArea) return;
    setCenter(centerArea);
  }, [centerArea]);

  useEffect(() => {
    if (managerListener.current) {
      managerListener.current.setSelectedZone(selectedZone);
    }
  }, [selectedZone]);

  return (
    <>
      <div
        className="map-container flex-grow-1"
        style={{ position: 'relative' }}
      >
        <Map
          mapId="CloserMap"
          defaultCenter={center}
          defaultZoom={1}
          mapContainerStyle={containerStyle}
          style={containerStyle}
          options={mapOptions}
        >
          {createZoneMode !== CreateZoneModes.DISABLED && (
            <MapControl position={ControlPosition.BOTTOM_CENTER}>
              <MapToolbar
                drawingManagerRef={drawingManagerRef}
                managerListener={managerListener}
                mapState={mapState}
                setMapState={setMapState}
              />
            </MapControl>
          )}
          {showStops && visibleStops?.length > 0 && (
            <StopsMarkers stops={visibleStops} />
          )}
          <ParcelShopMarkers />
          <AgencyMarkers />
        </Map>
      </div>
    </>
  );
};

CloserMap.propTypes = {};

export default withTranslation()(CloserMap);
