import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MapContext } from '../providers/mapProvider';
import { useTranslation } from 'react-i18next';
import ShipmentArea from './ShipmentArea';
import { CreateZoneModes } from '../dataGLS/CloserModes';
import EditZone from './EditZone';
import CloserModal from './CloserModal';
import { ZoneMovements, ZoneStatusCodes } from '../dataGLS/zoneData';
import moment from 'moment/moment';
import { zoneTypes } from '../dataGLS/zoneData';

const Zones = () => {
  const {
    createZone,
    zones,
    zonesBackup,
    polygons,
    polygonsBackup,
    createZoneMode,
    selectedZone,
    deleteZone,
    updatePriorityZone,
    setUpdatingZones,
    updateStatusZone,
    setSelectedZone,
    setCreateZoneMode,
    centerZone,
    history,
    drivers,
    setZonesPrint
  } = useContext(MapContext);
  const { t } = useTranslation();

  const [modal, setModal] = useState({});

  const smallColumn = () => {
    return createZoneMode !== CreateZoneModes.DISABLED;
  };

  const showModalDisableZone = zone => {
    console.log('disableAction');
    if (zone.status.code === ZoneStatusCodes.ENABLED) {
      setModal({
        header: t('Disable zone'),
        body: <p>{t('Disable zone warning')}</p>,
        buttonLabel: t('Disable zone'),
        show: true,
        callback: () => {
          console.log('Press disable on zone', zone);
          zone.status = {
            code: ZoneStatusCodes.DISABLED,
            at: moment().format('YYYY-MM-DDTHH:mm:ssZ')
          };
          updateStatusZone(zone);
        }
      });
    } else {
      setModal({
        header: t('Enable zone'),
        body: <p>{t('Enable zone warning')}</p>,
        buttonLabel: t('Enable zone'),
        show: true,
        callback: () => {
          console.log('Press disable on zone', zone);
          zone.status = {
            code: ZoneStatusCodes.ENABLED
          };
          updateStatusZone(zone);
        }
      });
    }
  };
  const showModalDeleteZone = zone => {
    console.log('deleteAction');
    let driver = t('Not assigned');
    if (zone.driver) {
      const driverAssigned = drivers.find(d => d.code === zone.driver.code);
      if (driverAssigned) {
        driver = driverAssigned.name;
      }
    }
    setModal({
      header: t('Delete zone'),
      body: (
        <>
          <p>{t('Delete zone warning')}</p>
          <p className={'mb-0'}>
            {t('Name')}: <strong>{zone.name}</strong>
          </p>
          <p className={'mb-0'}>
            {t('Code')}: <strong>{zone.code}</strong>
          </p>
          <p className={'mb-0'}>
            {t('Driver')}: <strong>{driver}</strong>
          </p>
        </>
      ),
      buttonLabel: t('Delete'),
      show: true,
      callback: () => {
        console.log('Press delete on zone', zone);
        deleteZone(zone);
      },
      buttonVariant: 'danger',
      buttonIcon: 'delete'
    });
  };

  const editAction = zone => {
    setSelectedZone(zone);
    centerZone(zone);
    zonesBackup.current = zones;
    polygonsBackup.current = polygons;
    setCreateZoneMode(CreateZoneModes.DATA);
    history.current = {
      past: [],
      present: polygons,
      future: []
    };
    console.log('History Edit', history.current);

    if (zone.zoneType === zoneTypes.PARCELSHOP) {
      setTimeout(() => {
        const event = new CustomEvent('zoneTypeChanged', {
          detail: { type: zoneTypes.PARCELSHOP }
        });
        window.dispatchEvent(event);

        const parcelShopIds = zone.parcelShops || [];

        // Disparar evento para abrir el selector de ParcelShop y preseleccionar los IDs existentes
        window.dispatchEvent(
          new CustomEvent('openParcelShopSelector', {
            detail: { parcelShopIds: parcelShopIds }
          })
        );

        window.dispatchEvent(
          new CustomEvent('unHideParcelShops', {
            detail: parcelShopIds
          })
        );

        const parcelShopButton = document.getElementById(
          'parcelShopEditZoneButton'
        );
        if (parcelShopButton) {
          parcelShopButton.click();
        }
      }, 50);
    }
  };

  /*
    start: índice de la zona a mover
    end: índice de la zona al que movemos
    isUp: booleano si el movimiento si el movimiento es descendente:
      true: Movimiento hacia 0
      false: movimiento hacia zones.length
   */
  const movePriorities = (start, end) => {
    const copyZones = structuredClone(zones);
    const isDescending = start > end;
    const updateZones = [];
    const movingZone = copyZones[start];
    //Guardamos la prioridad de end para asignar al final a la start
    const movingPriority = copyZones[end].priority || 1;
    //Cambiamos la prioridad de todas las zonas de la end a la start
    //Determinamos la dirección del bucle
    const step = isDescending ? 1 : -1;
    //Utilizamos el mismo bucle para ambos sentidos según el step
    for (let i = end; isDescending ? i < start : i > start; i += step) {
      const nextIndex = i + step;
      copyZones[i].priority = zones[nextIndex].priority;
      updateZones.push(copyZones[i]);
    }
    movingZone.priority = movingPriority;
    updateZones.push(movingZone);

    return updateZones;
  };

  const moveAction = (zone, index, movement) => {
    console.log('Move zone', zone);
    let updateZones = [];
    switch (movement) {
      case ZoneMovements.UP:
        // Si la prioridad es igual o superior a la de la primera zona O si el índice es la primera posición
        // es que ya tiene el valor más alto
        if (zone.priority >= zones[0].priority || index === 0) return;
        //Movemos con la posición anterior
        updateZones = movePriorities(index, index - 1);
        break;
      case ZoneMovements.DOWN:
        //Si la prioridad  igual a 1 O es igual o infeior a la primera zona o si el indice es la última posición
        // es que ya tiene el valor más bajo
        if (
          zone.priority === 1 ||
          zone.priority <= zones[zones.length - 1].priority ||
          index === zones.length - 1
        )
          return;

        //Movemos con la posición siguiente
        updateZones = movePriorities(index, index + 1);
        break;
      case ZoneMovements.TOP:
        // Si la prioridad es igual o superior a la de la primera zona O si el índice es la primera posición
        // es que ya tiene el valor más alto
        if (zone.priority >= zones[0].priority || index === 0) return;
        //Movemos con la primera posición
        updateZones = movePriorities(index, 0);
        break;
      case ZoneMovements.BOTTOM:
        //Si la prioridad  igual a 1 O es igual o inferior a la primera zona o si el indice es la última posición
        // es que ya tiene el valor más bajo
        if (
          zone.priority === 1 ||
          zone.priority <= zones[zones.length - 1].priority ||
          index === zones.length - 1
        )
          return;
        //Movemos con la última posición
        updateZones = movePriorities(index, zones.length - 1);
        break;
    }
    //setZonesAssign(reorderZones);
    setUpdatingZones(true);
    updatePriorityZone(updateZones);
  };

  const hideAllZones = () => {
    const allHidden = zones.every(zone => !zone.show);

    // If all zones are hidden, show them, if not, hide them
    const updatedZones = zones.map(zone => ({
      ...zone,
      show: allHidden
    }));

    setZonesPrint(updatedZones);

    // Si la zona está oculta, se ocultan los puntos de entrega y si está visible, se muestran
    zones.forEach(zone => {
      if (zone.zoneType === zoneTypes.PARCELSHOP) {
        const parcelShopIds = zone.parcelShops || [];

        if (zone.show) {
          window.dispatchEvent(
            new CustomEvent('hideParcelShops', {
              detail: parcelShopIds || []
            })
          );
        } else {
          window.dispatchEvent(
            new CustomEvent('unHideParcelShops', {
              detail: parcelShopIds || []
            })
          );
        }
      }
    });
  };

  return (
    <div className={`d-flex`}>
      <div
        className={`flex-col zones-col-inner ${
          smallColumn() ? 'small border-end' : ' flex-1'
        }`}
        style={{ minWidth: '100px' }}
      >
        <div className={`${!smallColumn() ? 'd-flex' : ''} p-3 `}>
          <div className={`${!smallColumn() ? 'd-flex' : ''} `}>
            <p className={`mb-0 lh-1 ${smallColumn() ? 'text-center' : ''}`}>
              <span className="material-symbols-outlined">stacks</span>
            </p>
            {!smallColumn() && (
              <div>
                <p className={'mb-0 ms-2 fw-bold'}>{t('Delivery areas')}</p>
                <p className={'mb-0 ms-2 fs-10'}>
                  {t('Total')}: {zones.length} {t('zones')}
                </p>
              </div>
            )}
          </div>
          {!smallColumn() && (
            <div className="d-flex gap-2 ms-auto">
              <Button
                className="rounded-pill"
                variant={
                  zones.every(zone => !zone.show)
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={hideAllZones}
              >
                {zones.every(zone => !zone.show) ? (
                  <span className="material-symbols-outlined">
                    visibility_off
                  </span>
                ) : (
                  <span className="material-symbols-outlined">visibility</span>
                )}
              </Button>
              <Button className={'btn-cta rounded-pill'} onClick={createZone}>
                {t('Add zone')}
              </Button>
            </div>
          )}
        </div>
        <div
          className={'overflow-y-auto'}
          style={{
            height: `calc(100vh - ${smallColumn() ? '134px' : '154px'})`
          }}
        >
          {zones.map((zone, index) => {
            return (
              <ShipmentArea
                zone={zone}
                key={zone.id}
                index={index}
                zones={zones}
                disableAction={showModalDisableZone}
                deleteAction={showModalDeleteZone}
                editAction={editAction}
                moveAction={moveAction}
              />
            );
          })}
          <ShipmentArea />
        </div>
      </div>
      {selectedZone && <EditZone setModal={setModal} />}
      <CloserModal
        header={modal.header}
        body={modal.body}
        buttonLabel={modal.buttonLabel}
        callback={modal.callback}
        show={modal.show}
        showCancelButton={modal.showCancelButton}
        buttonVariant={modal.buttonVariant}
        buttonIcon={modal.buttonIcon}
        setShow={val => {
          setModal(prevModal => {
            return { ...prevModal, show: val };
          });
        }}
      />
    </div>
  );
};

export default Zones;
