import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Alert } from 'react-bootstrap';
import { ReactComponent as ParcelShopIcon } from '../assets/img/icons/ps-mini-locker.svg';
import { ReactComponent as ParcelShopShopIcon } from '../assets/img/icons/ps-mini-shop.svg';
import { ReactComponent as AgencyIcon } from '../assets/img/icons/mini-agencia.svg';
import { CreateZoneModes } from '../dataGLS/CloserModes';
import { MapContext } from '../providers/mapProvider';
import { zoneTypes } from '../dataGLS/zoneData';

const ParcelShopSelect = ({
  options,
  value,
  onChange,
  placeholder,
  isMulti = false,
  isDisabled = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deselectedIds, setDeselectedIds] = useState([]);
  const containerRef = useRef(null);
  const { setCreateZoneMode, selectedZone } = useContext(MapContext);

  useEffect(() => {
    if (value) {
      // Si value es un array de objetos o un objeto, extraer los IDs
      if (Array.isArray(value)) {
        setSelectedIds(value.map(item => item.id || item));
      } else {
        setSelectedIds([value.id || value]);
      }
    } else {
      setSelectedIds([]);
    }
  }, [value]);

  useEffect(() => {
    if (isDisabled) {
      setIsOpen(false);
    }
  }, [isDisabled]);

  // Ocultar el selector si la zona no es de tipo PARCELSHOP
  useEffect(() => {
    if (selectedZone && selectedZone.type !== zoneTypes.PARCELSHOP) {
      setIsOpen(false);
    }
  }, [selectedZone]);

  // Escuchar el evento personalizado para abrir el selector y preseleccionar IDs
  useEffect(() => {
    const handleOpenSelector = event => {
      setIsOpen(true);

      // Si hay IDs de parcel shops en el evento, seleccionarlos
      if (
        event.detail &&
        event.detail.parcelShopIds &&
        event.detail.parcelShopIds.length > 0
      ) {
        const parcelShopIds = event.detail.parcelShopIds;
        setSelectedIds(parcelShopIds);

        // Encontrar las opciones completas para el callback onChange
        const selectedOptions = options.filter(option =>
          parcelShopIds.includes(option.id)
        );

        if (selectedOptions.length > 0) {
          onChange(isMulti ? selectedOptions : selectedOptions[0]);
        }
      }
    };

    // Añadir el listener para el evento
    window.addEventListener('openParcelShopSelector', handleOpenSelector);

    // Cleanup del listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('openParcelShopSelector', handleOpenSelector);
    };
  }, [options, onChange, isMulti]); // Dependencias del efecto

  // Escuchar evento para resetear deselectedIds
  useEffect(() => {
    const handleResetDeselected = () => {
      setDeselectedIds([]);
    };

    window.addEventListener('resetParcelShopDeselected', handleResetDeselected);
    return () => {
      window.removeEventListener(
        'resetParcelShopDeselected',
        handleResetDeselected
      );
    };
  }, []);

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = option => {
    setCreateZoneMode(CreateZoneModes.DATA);

    // Disparar evento para establecer el tipo de zona como PARCELSHOP
    const event = new CustomEvent('setZoneTypeParcelShop');
    window.dispatchEvent(event);

    // Disparar evento para agregar el Parcel Shop seleccionado a la lista
    const parcelShopEvent = new CustomEvent('parcelShopSelected', {
      detail: [option.id]
    });
    window.dispatchEvent(parcelShopEvent);

    // Disparar evento para centrar el mapa en los parcel shops seleccionados
    const centerEvent = new CustomEvent('centerParcelShops', {
      detail: {
        parcelShopIds: isMulti ? [...selectedIds, option.id] : [option.id]
      }
    });
    window.dispatchEvent(centerEvent);

    if (isMulti) {
      let newSelectedIds;
      const isSelected = selectedIds.includes(option.id);

      if (isSelected) {
        newSelectedIds = selectedIds.filter(id => id !== option.id);
        setDeselectedIds([...deselectedIds, option.id]);
      } else {
        newSelectedIds = [...selectedIds, option.id];
        setDeselectedIds(deselectedIds.filter(id => id !== option.id));
      }

      setSelectedIds(newSelectedIds);

      // Convertir IDs a objetos completos para el callback onChange
      const selectedOptions = options.filter(opt =>
        newSelectedIds.includes(opt.id)
      );
      onChange(selectedOptions);
    } else {
      setSelectedIds([option.id]);
      setDeselectedIds([]);
      onChange(option);
      setIsOpen(false);
    }
  };

  const isItemSelected = option => {
    return selectedIds.includes(option.id);
  };

  const getHeaderText = () => {
    if (!selectedIds.length) return placeholder;

    if (isMulti) {
      return `${selectedIds.length} seleccionados`;
    }

    // Encontrar el objeto completo para mostrar su label
    const selectedOption = options.find(option => option.id === selectedIds[0]);
    return selectedOption ? selectedOption.label : placeholder;
  };

  return (
    <div
      className="parcel-shop-select-container border-none"
      ref={containerRef}
    >
      <div
        className="parcel-shop-select-header d-flex align-items-center p-2 border rounded cursor-pointer"
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
        style={{
          borderColor: 'white !important',
          borderRadius: 'var(--gls-border-radius-pill)',
          color: 'var(--gls-primary)',
          fontWeight: 'bold',
          opacity: isDisabled ? 0.5 : 1,
          cursor: isDisabled ? 'not-allowed' : 'pointer'
        }}
      >
        {getHeaderText()}
        <span
          className="material-symbols-outlined ms-auto"
          style={{ color: 'var(--gls-primary)' }}
        >
          expand_more
        </span>
      </div>

      {isOpen && (
        <div
          className="parcel-shop-select-dropdown position-absolute w-100 bg-white border rounded shadow"
          style={{
            bottom: '115%',
            marginBottom: '0.25rem',
            minWidth: '500px',
            left: '-10%',
            minHeight: '304px'
          }}
        >
          <Form.Control
            type="text"
            placeholder="Busca el Parcel Shop"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="rounded-0 p-2 ps-5"
            style={{
              backgroundImage:
                'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="gray" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>\')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '1rem center',
              borderBottom: '1px solid #e8e8e8'
            }}
          />

          {selectedIds.length === 0 && (
            <Alert
              variant="warning"
              className="m-2 py-2 px-3 d-flex align-items-center"
              style={{ backgroundColor: '#FFDFAA', color: '#000000' }}
            >
              <span className="material-symbols-outlined text-warning me-2">
                warning
              </span>
              Las zonas Parcel Shop no pueden usarse junto con zonas dibujadas a
              mano o en base a un Código Postal.
            </Alert>
          )}

          {searchTerm && filteredOptions.length === 0 && (
            <div className="p-4 text-center text-muted">
              <span
                className="material-symbols-outlined d-block mb-2"
                style={{ fontSize: '2rem' }}
              >
                search_off
              </span>
              No se encontraron Parcel Shops que coincidan con tu búsqueda
            </div>
          )}

          <div
            className="parcel-shop-options"
            style={{ maxHeight: '262px', overflowY: 'auto' }}
          >
            {filteredOptions.map((option, index) => {
              const isDisabled =
                option.status === 'disabled' ||
                option.status === 'manuallyDisabled' ||
                (option.zone !== '' &&
                  option.status === 'enabled' &&
                  !deselectedIds.includes(option.id) &&
                  (!selectedZone ||
                    !selectedZone.parcelShops?.includes(option.id)));
              const selected = isItemSelected(option);

              return (
                <div
                  key={index}
                  className={`parcel-shop-option d-flex align-items-center justify-content-between p-2 ${
                    !isDisabled ? 'cursor-pointer hover-bg-light' : ''
                  } ${selected ? 'bg-light' : ''}`}
                  onClick={() => !isDisabled && handleSelect(option)}
                  style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                >
                  <div
                    className={`d-flex align-items-center flex-grow-1 ${
                      isDisabled ? 'opacity-50' : ''
                    }`}
                  >
                    {option.type === 'locker' ? (
                      <ParcelShopIcon className="me-2" />
                    ) : option.type === 'shop' ? (
                      <ParcelShopShopIcon className="me-2" />
                    ) : (
                      <AgencyIcon className="me-2" />
                    )}
                    <div>{option.label}</div>
                  </div>
                  <div className="ms-3 d-flex align-items-center">
                    {selected && (
                      <span
                        className="d-flex align-items-center justify-content-center px-2 py-1 rounded"
                        style={{ backgroundColor: '#BDE3BE', color: '#000000' }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center rounded-circle"
                          style={{
                            marginRight: '5px',
                            width: '18px',
                            height: '18px',
                            backgroundColor: '#BDE3BE',
                            border: '2px solid #2E7D32'
                          }}
                        >
                          <span
                            className="material-symbols-outlined"
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: '#2E7D32',
                              paddingTop: '2px'
                            }}
                          >
                            check
                          </span>
                        </div>
                        Seleccionada
                      </span>
                    )}
                    {option.status === 'disabled' ||
                    option.status === 'manuallyDisabled' ? (
                      <span
                        className="px-2 py-1 rounded"
                        style={{ backgroundColor: '#F0BABA', color: '#D32F2F' }}
                      >
                        Inactiva
                      </span>
                    ) : (
                      option.zone !== '' &&
                      option.status === 'enabled' &&
                      !selected &&
                      !deselectedIds.includes(option.id) &&
                      (!selectedZone ||
                        !selectedZone.parcelShops?.includes(option.id)) && (
                        <span
                          className="px-2 py-1 rounded"
                          style={{
                            backgroundColor: '#FFDFAA'
                          }}
                        >
                          Asignada a otra zona
                        </span>
                      )
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

ParcelShopSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      status: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default ParcelShopSelect;
