import React, { useContext } from 'react';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Button, Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { AuthContext } from '../../../providers/AuthProvider';
import { MapContext } from '../../../providers/mapProvider';
import { hideStops } from '../../../helpers/utils';

const TopNavRightSideNavItem = props => {
  const { t, i18n } = props;
  const { date, setDate } = useContext(AuthContext);
  const { manualAssign } = useContext(MapContext);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {!hideStops && (
        <li>
          <Button
            className={'ms-auto rounded-pill py-2'}
            variant={'danger'}
            onClick={() => {
              manualAssign();
            }}
          >
            {t('Assign shipments')}
          </Button>
        </li>
      )}
      <ProfileDropdown />
    </Nav>
  );
};

export default withTranslation()(TopNavRightSideNavItem);
