import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback, memo, useMemo, useState } from 'react';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { LightPin, InfoContent } from './StopsMarkers';

const StopMarker = memo(props => {
  const { t, stop, setMarkerRef, totalMarkers } = props;
  const [showInfo, setShowInfo] = useState(false);

  // Determinar si debe ser clicable (solo para cantidades manejables)
  const isClickable = totalMarkers < 1000;

  // Usar callback para evitar recreación de función
  const ref = useCallback(
    marker => setMarkerRef(marker, stop.id),
    [setMarkerRef, stop.id]
  );

  // Memoizar coordenadas para evitar recálculos
  const position = useMemo(
    () => ({
      lat: stop.address.location.coordinates.lat,
      lng: stop.address.location.coordinates.lng
    }),
    [stop.address.location.coordinates]
  );

  // Optimizar opciones del marcador dependiendo de la cantidad
  const options = useMemo(
    () => ({
      clickable: isClickable,
      collisionBehavior: 'OPTIONAL_AND_HIDES_LOWER_PRIORITY'
    }),
    [isClickable]
  );

  const handleClick = useCallback(() => {
    if (isClickable && stop.id) {
      setShowInfo(prevShow => !prevShow);
    }
  }, [isClickable, stop.id]);

  const handleCloseInfo = useCallback(() => {
    setShowInfo(false);
  }, []);

  // Renderizado condicional directo para evitar cálculos innecesarios
  if (!isClickable) {
    return (
      <AdvancedMarker position={position} ref={ref} options={options}>
        <LightPin />
      </AdvancedMarker>
    );
  }

  return (
    <>
      <AdvancedMarker
        position={position}
        ref={ref}
        options={options}
        onClick={handleClick}
      >
        <LightPin />
      </AdvancedMarker>

      {showInfo && (
        <InfoWindow position={position} onCloseClick={handleCloseInfo}>
          <InfoContent t={t} stop={stop} onClose={handleCloseInfo} />
        </InfoWindow>
      )}
    </>
  );
});

StopMarker.propTypes = {
  t: PropTypes.func,
  stop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string,
    service: PropTypes.string,
    address: PropTypes.shape({
      location: PropTypes.shape({
        coordinates: PropTypes.shape({
          lat: PropTypes.number.isRequired,
          lng: PropTypes.number.isRequired
        }).isRequired
      }).isRequired,
      postalCode: PropTypes.string
    }).isRequired
  }).isRequired,
  setMarkerRef: PropTypes.func.isRequired,
  totalMarkers: PropTypes.number
};

// Valor por defecto
StopMarker.defaultProps = {
  totalMarkers: 0
};

export default withTranslation()(StopMarker);
